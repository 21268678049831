import React, { useEffect } from 'react';
// import SVG from 'react-inlinesvg';
import { Row, Col, Button, Container } from 'react-bootstrap';
import stickyScroll from './stickyScroll';
import PhoneLink from '../PhoneLink';
// import CtaButtons from '../CtaButtons';
import Link from 'next/link';
import { useFranchiseeContext } from '../../context/Franchisee';
// import styles from './styles';

interface StickyCtaProps {
  // add sticky cta props here
  booking?: boolean;
}

const StickyCta: React.FC<StickyCtaProps> = ({ booking }: StickyCtaProps) => {
  // handle sticky CTA events
  useEffect(stickyScroll);
  const { phonePrimary } = useFranchiseeContext()?.franchisee;

  if (!booking) {
  return (
    <>
      <div className='sticky-cta bg-primary'>
        <div className="sticky-footer-cta-section">
          <div className="sticky-footer-cta-section-buttons">
            <Link href='/book'><a>Book Your Cleaning</a></Link>
            <Link href='/book'><a>Calculate Your Price</a></Link>
          </div>
          <div className='call-for-estimate-text'>
            <p>Call for a Free Estimate!</p>
            <PhoneLink number={phonePrimary} className='link-inverse' />
          </div>
        </div>
      </div>
      {/* <style jsx global>
        {styles}
      </style> */}
    </>
  );
};
}
StickyCta.defaultProps = {
  booking: false,
};

export default StickyCta;
