/**
 * Sticky CTA until it hits the footer
 */
// import ScrollOut from "scroll-out"
// import "../util/keyboardHook"

const ScrollOut = require('scroll-out');

const stickyScroll = () => {
  /*
    Types must be set to HTMLElement.
    Element does not allow for the use of dataset or style, and fails both linting and jest
  */
  const stickyCta : HTMLElement = document.querySelector('.sticky-cta');
  const footer : HTMLElement = document.querySelector('.footer-nav-section');
  const header : HTMLElement = document.querySelector('.header');

  if (!stickyCta || !footer) {
    return;
  }

  // When it becomes "sticky"
  const stick = () => {
    // Stick, but only if the footer isn't "in"
    if (footer.dataset.scroll === 'in') {
      return;
    }

    stickyCta.classList.add('sticky-cta--stuck');
    stickyCta.classList.remove('sticky-cta--hidden');
    footer.style.marginTop = `${stickyCta.clientHeight}px`;
  };
  
  // When it becomes "unsticky"
  const unstick = () => {
    stickyCta.classList.remove('sticky-cta--stuck');
    stickyCta.classList.add('sticky-cta--hidden');
    footer.style.marginTop = '0';
  };

  // Sticks until it hits the footer
  ScrollOut({
    targets: footer,
    onShown: () => unstick(),
    onHidden: () => stick(),
  });

  if (!stickyCta.dataset.persistent) {
    // Sticks on first scroll, disappears at the top of the page
    ScrollOut({
      targets: header,
      offset: 1,
      onShown: () => stick(),
      onHidden: () => unstick(),
    });
  }

  // Hide when virtual keyboard is visible
  window.addEventListener('keyboardFocus', () => {
    unstick();
  });

  // Show when virtual keyboard blurs
  window.addEventListener('keyboardBlur', () => {
    stick();
  });
};

export default stickyScroll;
